<template>
  <svg viewBox="0 0 11 10" xmlns="http://www.w3.org/2000/svg" version="1.1"><g fill-rule="evenodd">
        <path d="M5.5 0l1.235 3.8h3.996L7.498 6.15l1.235 3.8L5.5 7.6 2.267 9.95l1.235-3.8L.269 3.8h3.996L5.5 0z" />
      </g>
    </svg>
</template>

<script>
export default {
  name: 'star',
}
</script>
